(function ($) {
  $(document).ready(function () {

    $('a[href^="#"]:not(a.menu-trigger,#back_to_top a)').on('click', function (e) {

      var $href = $(this).attr('href'),
        $tgt = $($href == "#" || $href == "" ? 'html' : $href),
        $pos = $tgt.offset().top,
        $correctH = 65;
      
        if(window.innerWidth > 1000){
          
          if($('.navigation__fix__wrap').hasClass('fixed')){
            $correctH = 90;
          }else{
            $correctH = 200;
          }
          
        }
      
      $("html, body").animate({
        scrollTop: $pos - $correctH
      }, 500);
      e.preventDefault();

    });



    var mql = window.matchMedia("(min-width : 1000px)");
    mql.addListener(menuClose);
    menuClose(mql);

    function menuClose(mql) {
      if (mql.matches) {
        $("#menu").removeClass('menu_open');
        $('.menu-trigger').removeClass('triggeractive');
      }
    }

    $('.slider').slick({
      arrows: false,
      autoplay: true,
      fade: true,
      speed: 500,
      pauseOnHover: false
    });
    
    




    // gmap iframe
    $('.gmap').click(function () {
      $('.gmap iframe').css("pointer-events", "auto");
    });

  });



  $('.datepicker').datepicker();
  
})(jQuery);

window.addEventListener('load',function(){
  document.getElementById('loading').classList.add('finished');
})



window.addEventListener('DOMContentLoaded', function () {
  
  //set noopener
  
  var $links = document.querySelectorAll('a[target="_blank"]');
  [].forEach.call($links,function(link){
    
    if(link.hasAttribute('rel') === false){
      link.setAttribute('rel','noopener');
    }

  })
  
  
  
  const openKey = document.querySelectorAll('.open__key');
  const hiddenBlock = document.querySelectorAll('.hidden__block');
  

  if(openKey.length > 0){
    [...openKey].forEach((elm)=>{
      const openTgt = elm.nextElementSibling;

      elm.addEventListener('click',function(){
        openTgt.classList.toggle('is--active');
      })
    })
  }
  
  
  if(hiddenBlock.length > 0){
    [...hiddenBlock].forEach((elm)=>{
      const closeBtn = elm.querySelector('.close__opend__block');
      if(closeBtn !== null){
        closeBtn.addEventListener('click',function(){
          elm.classList.remove('is--active');
        })
      }
    })
  }
  

  //breadcrumb break br
  
  const breadcrumbLi = document.querySelectorAll('.breadcrumb li, .footer__sitemap li');

  if(breadcrumbLi){
    [...breadcrumbLi].forEach((elm)=>{
      const breadcrumbLiTxt = elm.innerHTML;
      const replacedTxt = breadcrumbLiTxt.replace(/<br>/g, '');
      elm.innerHTML = replacedTxt;
    })
    

  }
  



  //hmb
  
  var toggle = document.getElementById('toggle');
  var menu = document.getElementById('menu');
  var gnav = document.querySelector('.site__navigation');
  var menuTrigger = document.querySelector('.menu-trigger');
  var menuLists = document.querySelectorAll('#menu li a');
  var body = document.querySelector('body');
  
  var changeList = [toggle,menu,gnav,menuTrigger,body]
  
  
  toggle.addEventListener('click', function (e) {
    menu.style.transition = "all 0.5s ease-in-out";
    e.preventDefault();
    
    changeList.forEach((elm)=>{
      console.log(elm)
      elm.classList.toggle('menu--open')
    })
    
    /*menu.classList.toggle('menu_open')
    toggle.classList.toggle('active');
    body.classList.toggle('menuopen');
    gnav.classList.toggle('active');
    menuTrigger.classList.toggle('triggeractive');*/
  });
  
  window.addEventListener('resize',function(){
    if(window.innerWidth >= 1000){
      menu.style.transition = "none";
      changeList.forEach((elm)=>{
        elm.classList.remove('menu--open')
      })
    }
  });
  
  menu.addEventListener('click',function(e){
    
    if(window.innerWidth < 1000){
      e.preventDefault();
      changeList.forEach((elm)=>{
        elm.classList.remove('menu--open')
      })
    }
  });


  function addMenu(el) {
    var el = document.getElementById(el);
    var elHtml = el.innerHTML;

    var menuWrap = document.querySelector('#menu .menu__wrap');
    var li = document.createElement('li');
    li.classList.add('sp_only');
    li.classList.add('sns');
    li.innerHTML = elHtml;
    menuWrap.appendChild(li);
  }

  addMenu("sns_list");
  addMenu("gnavi-line");

  
  function stopProp() {
    var menuWrap = document.querySelector('#menu .menu__wrap');
    menuWrap.addEventListener('click',function(e){
      e.stopPropagation();
    })
  }

  stopProp();

  objectFitImages('.ofi');
  
  
  
  //table
  new ScrollHint('.js-scrollable', {
    scrollHintIconAppendClass: 'scroll-hint-icon-white', // white-icon will appear
    i18n: {
      scrollable: 'スクロールできます'
    }
  });
  
  

  
});





window.addEventListener('load',function(){
  //const loading = document.querySelector('#loading');
  //loading.classList.add('finished');
})



//fixed


const mainvisual = document.querySelector('.mainvisual');
const navigationFixWrap = document.querySelector('.navigation__fix__wrap');
var scrPosition = navigationFixWrap.getBoundingClientRect().top + window.pageYOffset;

function headerFix(){
  
  var scr = window.pageYOffset;

  /*if(document.body.classList.contains('home')){
    scrPosition = mainvisual.getBoundingClientRect().top + mainvisual.offsetHeight + window.pageYOffset;
  }else{*/
    //scrPosition = //window.innerHeight / 5

  //}
  


  if(scr > scrPosition){
    navigationFixWrap.classList.add('fixed');
  }else{
    navigationFixWrap.classList.remove('fixed');
  }
}


window.addEventListener('scroll',headerFix);
window.addEventListener('load',headerFix);



